<template lang="">
  <v-container
    v-if="
      user.permissions.indexOf(permissionsAll.brand_create) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <FromBrand
      :actionForm="actionForm"
      @sendForm="sendForm"
      ref="formComponent"
    />
  </v-container>
</template>
<script>
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import { BreadCrumbs } from "../../components/_commons";
import { FromBrand } from "../../components/brands";
import { mapState } from "vuex";
import permissions from "../../helpers/permissions";

export default {
  name: "BrandCreate",
  components: {
    BreadCrumbs,
    FromBrand,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.brandCreate,
      actionForm: "create",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async sendForm(formData) {
      await beginRequest();
      const { status, snack } = await this.$store.dispatch(
        "createBrand",
        formData
      );
      if (status >= 200 && status < 300) {
        this.$refs.formComponent.resetFormMethod();
      }
      await endRequest(snack, status);
    },
  },
  async created() {
    await this.$store.dispatch("removeBrandSelectedAction");
  },
};
</script>
